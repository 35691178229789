<template>
    <v-data-table
        :headers="headers"
        :items="categories"
        hide-default-footer
        disable-pagination
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Категории</v-toolbar-title>
                <v-spacer />
                <v-btn color="primary" @click="create">
                    <v-icon>mdi-plus</v-icon> Добавить
                </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.parent`]="{ item }">
            {{ item.parent ? item.parent.title : '-' }}
        </template>
        <template v-slot:[`item.enabled`]="{ item }">
            <span :class="`true-false-label ${getBadge(item.enabled)}`">
                &nbsp;{{item.enabled ? 'Да' : 'Нет' }}&nbsp;
            </span>
        </template>
        <template v-slot:[`item.open`]="{ item }">
            <a :href="`/categories/${item.id}/detail`">
                <v-icon color="primary">mdi-eye</v-icon>
            </a>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <a :href="`/categories/${item.id}/edit`">
                <v-icon color="primary">mdi-pencil</v-icon>
            </a>
        </template>
    </v-data-table>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { priceFormated } from '../../helpers';

    export default {
        name: 'ProductsList',
        components: {
        },
        data: () => ({
            priceFormated,
            headers: [
                // { text: 'Дата', value: 'created_at' },
                { text: 'Код', value: 'code' },
                { text: 'Сортировка', value: 'position' },
                { text: 'Родитель', value: 'parent' },
                { text: 'Заголовок', value: 'title' },
                { text: 'Активен', value: 'enabled' },
                { text: '', align: 'right', value: 'open' },
                { text: '', align: 'right', value: 'edit' },
            ],
        }),
        computed: {
            ...mapState('categories', {
                categories: state => state.entities
            }),
        },
        async mounted() {
            await store.dispatch('categories/fetch', {});
        },
        methods: {
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            async create() {
                await this.$router.push({ name: 'categories-create' });
            },
            async open(id) {
                await this.$router.push({ name: 'categories-detail', params: { id } });
            }
        }
    }
</script>